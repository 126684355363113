<template>
    <CardLayout :item="item" :keys="keys" :state="state" v-if="false"/>

    <v-hover v-slot:default="{hover}" v-else>
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="item.state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="grid.md">
                        <span class="font-weight-bold">
                            {{item.date}} • <span class="gold--text">{{item.id}}</span>
                        </span>
                    </v-col>
                    <v-col cols="12" :md="grid.md" class="font-weight-medium">
                        <span class="gold lighten-1 white--text px-3 rounded">{{item.product}}</span>
                        <span class="tag-status white--text rounded px-3 ml-1" :class="state"> {{item.status}}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-circle</v-icon>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.customer }}</span>
                        </v-row>

                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-row v-if="true" align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                        <v-col v-bind="layout" v-for="v in keys" :key="v.value">
                            <v-row v-if="v.value !== 'coins' || item.raw_data.product_type !== 'productgoldt3'" no-gutters align="center">
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys(getTextKey(v.value)) }}</v-col>
                                <v-col cols="12" class="gold--text">{{ g_isDefined(item[v.value]) }}</v-col>
                            </v-row>
                            <v-row v-else no-gutters align="center">
                                <v-col cols="12" class="caption font-weight-bold"></v-col>
                                <v-col cols="12" class="gold--text"></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>

                <!--FOOTER-->
                <v-row justify="center" align="center" no-gutters>
                    <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                        <v-icon size="20" class="px-1">mdi-account-tie</v-icon>
                        <span class="caption">{{ item.seller }}</span>
                    </v-row>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
import CardLayout from "@/components/layouts/CardLayout";

export default {
    name: "AgreementReportCard",
    components: {CardLayout},
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        grid: {sm: 6, md: 6, lg: 2},
        card: { cols: 6, xs: 6, sm: 4, md: 1, xl: 0, align: 'center', class: 'my-2 full-cols full-cols-5'}
    }),
    computed: {
        layout() {
            return this.card;
        }
    },
    methods: {
        getTextKey(value) {
            if (value === 'target_price' && this.item.raw_data.product_type === 'productgoldt3') {
                return 'target_investment_t3'
            } else {
                return value
            }
        }
    }
}
</script>